import {
  get, post, downloadByGet, Delete,
} from '@/libs/axios'

export const GetPrivateSankeyChart = params => post('/external/share/data/sankeyChart', params)

export const GetAgreementListByPage = params => post('/thirdParty/agreement/GetAgreementListByPage', params)

export const GetShareDataManageListByPage = params => post('/external/share/data/page', params)
export const GetDetailById = params => post('/thirdParty/agreement/GetDetailById', params)
export const DeleteAgreementByIds = params => post('/thirdParty/agreement/DeleteAgreementByIds', params)

export const GetSatisfaction = () => get('/external/share/data/satisfaction')
export const GetTemplateList = () => get('/thirdParty/agreement/GetTemplateList')

export const GetTemplateContent = id => get(`/thirdParty/agreement/GetTemplateContent?templateCode=${id}`)
export const GetUpdateInfo = id => get('/external/share/data/info', id)
export const GetThirdPartyShareFieldName = id => post('/pia/assess/GetThirdPartyShareFieldName', id)
export const PreviewByContent = id => get(`/thirdParty/agreement/PreviewByContent?id=${id}`)
export const DeleteShareDataManageByIds = id => Delete(`/external/share/data/delete?id=${id}`)
export const GetProductFieldList = id => post('/pia/assess/GetProductFieldList', id)

export const saveOrUpdateShareData = params => {
  const submitUrl = params.id ? '/external/share/data/update' : '/external/share/data/create'
  return post(submitUrl, params)
}
export const SaveAndUpdateAgreement = params => {
  const submitUrl = params.id ? '/thirdParty/agreement/SaveAndUpdateAgreement' : '/thirdParty/agreement/CreateAgreementByTemplate'
  return post(submitUrl, params)
}
//
export const ShareDataExport = () => downloadByGet('/external/share/data/export')
export const DownloadByContent = id => downloadByGet('/thirdParty/agreement/DownloadByContent', { id })
