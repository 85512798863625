import {
  get, post, downloadByGet, Delete,
} from '@/libs/axios'
import { assessment } from '@/@core/utils/constant'

export const GetAssessQuestionTypes = params => post('/pia/assess/GetAssessQuestionTypes', params)
export const GetAssessQuestions = params => post('/pia/assess/GetAssessQuestions', params)
export const GetAssessTemplateList = () => get('/pia/assess/GetAllAssessTypeList')
export const AssessCategoryInfo = assessCode => get(`/assess/category/info?assessCode=${assessCode}`)
export const GetQuestionOptionRisk = params => post('/pia/assess/GetQuestionOptionRisk', params)
export const GetPiaAssessFinishCountById = id => post('/pia/assess/GetPiaAssessFinishCountById', id)
export const DelPia = id => post('/pia/assess/DeleteAssessByIds', id)
export const GetPiaAssessById = id => post('/pia/assess/GetPiaAssessById', id)

export const GetPiaAssessAuditListByPage = params => post('/pia/assess/GetPiaAssessAuditListByPage', params)

export const UpdateAssessAuditStatus = params => post('/pia/assess/UpdateAssessAuditStatus', params)
export const FinishPiaAssessAudit = params => post('/pia/assess/FinishPiaAssessAudit', params)
export const FinishPiaAssessAnswer = params => post('/pia/assess/FinishPiaAssessAnswer', params)
export const GetLastAuditInfo = params => post('/pia/assess/GetLastAuditInfo', params)
export const DownloadReport = id => downloadByGet('/pia/assess/DownloadPiaAssessReportPDF', { id })

export const GetTemplateInfo = (assessCode, templateCode) => get(`/assess/${assessCode}/template/info?templateCode=${templateCode}`)
export const DelAssement = (assessCode, id) => Delete(`/assess/${assessCode}/delete?id=${id}`)
export const GetAssessmentInfo = (assessCode, id) => get(`/assess/${assessCode}/info?id=${id}`)
export const WriteFinish = (assessCode, id) => get(`/assess/${assessCode}/writer/finish?id=${id}`)
export const AuditFinish = (assessCode, id) => get(`/assess/${assessCode}/audit/finish?id=${id}`)
export const AssessFinish = (assessCode, params) => post(`/assess/${assessCode}/finish`, params)
export const GetrateProgress = (assessCode, id) => get(`/assess/${assessCode}/rate_progress?id=${id}`)
export const GetQuestionAffiliate = (assessCode, assessId, qCode) => get(`/assess/${assessCode}/question/affiliate?assessId=${assessId}&qCode=${qCode}`)
export const GetQuestionRisk = (assessCode, assessId, qCode) => get(`/assess/${assessCode}/question/risk?assessId=${assessId}&qCode=${qCode}`)
export const DownloadAnnex = (assessCode, assessId, qCode) => downloadByGet(`/assess/${assessCode}/question/download/annex?assessId=${assessId}&qCode=${qCode}`)
export const DownloadAssessmentReport = (assessCode, id) => downloadByGet(`/assess/${assessCode}/download/report?id=${id}`)
export const GetAssessBaseInfo = (assessCode, id) => get(`/assess/${assessCode}/base_info?id=${id}`)
export const RemoveAnnex = (assessCode, assessId, qCode) => Delete(`/assess/${assessCode}/remove/annex?assessId=${assessId}&qCode=${qCode}`)

//
export const AssementUpLoadAttachment = (assessCode, params) => post(`/assess/${assessCode}/upload/annex`, params)
export const QuestionAudit = (assessCode, params) => post(`/assess/${assessCode}/question/audit`, params)

// 获取评估统计
export const GetSatisfaction = assessmentType => get(`/assess/${assessment[assessmentType].assessCode}/status_statistics`)
// 评估列表

export const AssementPage = (assessmentType, params) => {
  const submitUrl = `/assess/${assessment[assessmentType].assessCode}/page`
  return get(submitUrl, params)
}
// 评估模板
export const GetThirdPartyPublicTemplates = () => get('/pia/assess/GetThirdPartyPublicTemplates')
export const AssessmentTemplate = assessmentType => {
  const submitUrl = `/assess/${assessment[assessmentType].assessCode}/template/list`
  return get(submitUrl)
}
// 获取问题列表
export const GetAssessQuestionList = (assessCode, assessId, qTypeCode) => get(`/assess/${assessCode}/question/list?assessId=${assessId}&qTypeCode=${qTypeCode}`)
export const GetAssessQuestionType = (assessCode, assessId) => get(`/assess/${assessCode}/question/type?assessId=${assessId}`)
//

export const CommitAnswer = (assessCode, params) => post(`/assess/${assessCode}/question/answer`, params)
export const saveOrUpdatePiaAssess = (assessCode, params) => {
  const submitUrl = params.id ? `/assess/${assessCode}/update` : `/assess/${assessCode}/create`
  return post(submitUrl, params)
}
