import { downloadByGet, get, post } from '@/libs/axios'

export const getAssetsByPage = params => post('/enterprise/assets/Page', params)
export const getAssetsById = params => post('/enterprise/assets/GetInfoById', params)
export const ImportAssetsInfo = params => post('/enterprise/assets/ImportAssetsInfo', params)
export const GetUpdateInfoById = params => post('/enterprise/assets/GetUpdateInfoById', params)
// 查询资产下来
export const QueryAssetsOptions = (assetsType, productId, typeName) => {
  const params = {}
  const url = '/enterprise/assets/QueryOptions'
  if (assetsType !== undefined) {
    params.assetsType = assetsType
  }
  if (productId !== undefined) {
    params.productId = productId
  }
  if (typeName !== undefined) {
    params.typeName = typeName
  }
  return get(url, params)
}
export const QueryResponsiblePerson = () => get('/enterprise/assets/QueryResponsiblePerson')
export const GetAssetsALLocation = () => get('/enterprise/assets/GetAssetsALLocation')
export const GetAssetsSatisfactionByProduct = () => get('/enterprise/assets/GetAssetsSatisfactionByProduct')
export const GetAssetsSatisfactionByLocation = limit => get(`/enterprise/assets/GetAssetsSatisfactionByLocation?limit=${limit}`)
export const GetSatisfactionByALLocation = () => get('/enterprise/assets/GetSatisfactionByALLocation')
export const GetAllAssetsTypeList = () => get('/enterprise/assets/GetAllAssetsTypeList')
export const GetAssetsTypeOptions = () => get('enterprise/assets/GetAssetsTypeOptions')
export const QueryAssetsOptionsByType = params => post('/enterprise/assets/QueryOptionsByType', params)
export const GetThirdPartyOptionList = () => get('/enterprise/thirdParty/GetOption')
export const FilterThirdPartyOptions = () => get('/thirdParty/shareData/FilterThirdPartyOptions')
export const GetThirdPartySelect = () => get('/third/party/select')
export const GetAssetsSatisfaction = () => get('/enterprise/assets/GetAssetsSatisfaction')
export const deleteAssets = params => post('/enterprise/assets/Delete', params)

export const saveOrUpdateAssest = params => {
  const submitUrl = params.id ? '/enterprise/assets/Update' : '/enterprise/assets/Create'
  return post(submitUrl, params)
}
export const DownloadAssetsImportTemplate = () => downloadByGet(`/enterprise/assets/DownloadAssetsImportTemplate?_t=${Date.parse(new Date())}`)
export const DownloadImportTemplate = () => downloadByGet(`/assets/data/download/template?_t=${Date.parse(new Date())}`)
export const PrivacyFieldImportData = params => post('/assets/data/import', params)
