<template>
  <b-card>
    <el-form
      ref="form"
      v-loading="loading"
      style="width: 50%"
      :model="form"
      label-width="80px"
    >
      <el-form-item
        label="标题"
        prop="assessName"
        :rules="[ { required: true, message: '标题不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.assessName"
          maxlength="100"
        />
      </el-form-item>
      <div v-if="opportunityList.length > 0">
        <el-form-item
          class="scance-form-item"
          label="评估时机"
          prop="opportunityList"
          :rules="[ { required: true, message: '评估时机不能为空', trigger: 'change' }]"
        >
          <el-checkbox-group
            v-model="form.opportunityList"
            style="margin-top:6px"
          >
            <ul>
              <li
                v-for="(item,index) in opportunityList"
                :key="index"
              >
                <el-checkbox :label="item" />
              </li>
            </ul>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div v-if="conditionsList.length > 0">
        <el-form-item
          class="scance-form-item"
          label="触发条件"
          prop="conditionsList"
          :rules="[ { required: true, message: '触发条件不能为空', trigger: 'change' }]"
        >
          <el-checkbox-group
            v-model="form.conditionsList"
            style="margin-top:6px"
          >
            <ul>
              <li
                v-for="(item,index) in conditionsList"
                :key="index"
              >
                <el-checkbox :label="item" />
              </li>
            </ul>
          </el-checkbox-group>
        </el-form-item>
      </div>

      <el-form-item label="评估模板">
        {{ assessTypeName }}
      </el-form-item>
      <el-form-item
        v-if="form.assessTargetType > 0"
        label="评估对象"
        maxlength="100"
        prop="assessTargetId"
        :rules="[ { required: true, message: '评估对象不能为空', trigger: 'change' }]"
      >
        <el-select
          v-if="form.assessTargetType !== 1"
          v-model="form.assessTargetId"
          placeholder="请选择评估对象"
          @change="form.fieldIdList=[];getProductFieldList()"
        >
          <el-option
            v-for="(item,index) in assessObjectOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <SelectTree
          v-else
          v-model="form.assessTargetId"
          :props="treeSelectProps"
          :options="deptOptions"
        />
      </el-form-item>
      <!--      targetFieldOperate 1 显示字段 2 修改字段-->
      <el-form-item
        v-if="targetFieldOperate === 1"
        label="共享字段"
      >
        <el-select
          v-model="form.fieldIdList"
          placeholder="请选择采集的数据字段"
          multiple
        >
          <el-option
            v-for="(item,index) in sourceFiledList"
            :key="index"
            :label="item.fieldName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="targetFieldOperate === 2 && sourceFiledList.length > 0"
        label="共享字段"
      >
        <div class="flex-start-wrap">
          <el-tag
            v-for="(item,index) in sourceFiledList"
            :key="index"
            class="mr10 mb10"
          >
            {{ item.fieldName }}
          </el-tag>
        </div>
      </el-form-item>
      <el-form-item
        v-if="!isOutsidersWriter"
        label="评估人员"
        prop="assessUserList"
        :rules="[ { required: true, message: '用户不能为空', trigger: 'change' }]"
      >
        <div class="flex-start">
          <div class="mr10">
            已选择<span style="color: #0d40b2;font-size: 14px;margin: 0 5px">
              {{ form.assessUserList.length }}
            </span>个
          </div>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="personSelectDialogShow"
          >
            添加人员
          </el-button>
        </div>
      </el-form-item>
      <div class="flex-start">
        <el-form-item
          label="开始时间"
          prop="assessBeginTime"
          :rules="[ { required: true, message: '时间不能为空', trigger: 'change' }]"
        >
          <el-date-picker
            v-model="form.assessBeginTime"
            format="yyyy-MM-dd"
            placement="bottom-start"
            value-format="yyyy-MM-dd"
            type="date"
            :picker-options="pickerOptionsStart"
            placeholder="选择日期"
            @change="changeEnd"
          />
        </el-form-item>
        <el-form-item
          label="结束时间"
          prop="assessEndTime"
          :rules="[ { required: true, message: '时间不能为空', trigger: 'change' }]"
        >
          <el-date-picker
            v-model="form.assessEndTime"
            format="yyyy-MM-dd"
            placement="bottom-start"
            value-format="yyyy-MM-dd"
            type="date"
            :picker-options="pickerOptionsEnd"
            placeholder="选择日期"
            @change="changeStart"
          />
        </el-form-item>
      </div>

      <el-form-item
        label="描述"
        maxlength="100"
        prop="description"
      >
        <el-input
          v-model="form.description"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
        v-if="isProcess"
        label="审核人员"
        prop="auditor"
        :rules="[ { required: true, message: '用户不能为空', trigger: 'change' }]"
      >
        <div class="flex-start">
          <div class="mr10">
            已选择<span style="color: #0d40b2;font-size: 14px;margin: 0 5px">
              {{ form.auditor ? 1 : 0 }}
            </span>个
          </div>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="personSelectDialogShow2"
          >
            添加人员
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="formSubmitted"
      >
        提 交
      </el-button>
    </div>
    <PersonSelect
      ref="personSelect"
      :p-user-ids.sync="form.assessUserList"
      :multiple="true"
    />
    <PersonSelect
      ref="personSelect2"
      :p-user-id.sync="form.auditor"
    />
  </b-card>

</template>

<script>
import SelectTree from '@core/components/select-tree/SelectTree.vue'
import {
  saveOrUpdatePiaAssess, GetPiaAssessById, GetTemplateInfo, GetAssessmentInfo,
} from '@/api/pia/pia'
import { success, error, treeDataTranslate } from '@core/utils/utils'
import { getAllProduct } from '@/api/enterprise/product'
import { queryUserOptions } from '@/api/system/system'
import PersonSelect from '@core/components/person-select/PersonSelect.vue'
import { GetProductFieldList } from '@/api/thirdPartCompliance/thirdPartCompliance'
import { GetThirdPartyOptionList } from '@/api/enterprise/assest'
import { ListDept, QueryDeptOptions } from '@/api/system/dept/dept'

export default {
  components: { PersonSelect, SelectTree },
  data() {
    const assessmentType = Number(this.$route.query.t)
    return {
      fristInit: true,
      isOutsidersWriter: false,
      isProcess: false,
      loading: false,
      assessObjectOptions: [],
      sourceFiledList: [],
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'label', // 显示名称
        children: 'children', // 子级字段名
      },
      users: [],
      deptOptions: [],
      templateCode: this.$route.query.c,
      assessCode: this.$route.query.assessCode,
      pId: Number(this.$route.query.pId),
      opportunityList: [],
      conditionsList: [],
      assessTypeName: '',
      form: {
        id: 0,
        conditionsList: [],
        opportunityList: [],
        assessName: '',
        questionnaireCode: '',
        assessEndTime: '',
        assessBeginTime: '',
        description: '',
        assessTargetType: '', // 1：组织，2：业务，3：第三方，4：IT资产
        assessTargetId: '',
        auditor: '',
        assessUserList: [],
        fieldIdList: [],
      },
      targetFieldOperate: '',
      assessmentType, // 评估类型 1 PIA评估
      pageTitleObj: {
        1: '合规评估',
        2: '合规评估',
        3: '合规评估',
        4: '第三方合规管理',
        5: '第三方合规管理',
      },
      subTitleObj: {
        1: 'PIA评估',
        2: '认证评估',
        3: '快速评估',
        4: '个人信息共享评估',
        5: '第三方合规评估',
      },
      routePathObj: {
        1: '/pia',
        2: '/compliance-analysis',
        3: '/compliance-list',
        4: '/sharedAssessment',
        5: '/thirdPartCompliance',
      },
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.form.assessEndTime) {
            return time.getTime() < Date.now() - 8.64e7 || time.getTime() > new Date(this.form.assessEndTime).getTime()
          }
          return time.getTime() < Date.now() - 8.64e7
        },
      },
      pickerOptionsEnd: {
        disabledDate: time => time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.form.assessBeginTime).getTime() - 24 * 60 * 60 * 1000,
      },
    }
  },
  mounted() {
    this.navActiveInit(1, this.subTitleObj[this.assessmentType], this.pageTitleObj[this.assessmentType])
    this.fristInit = false
  },
  destroyed() {
    this.navActiveInit(2, this.subTitleObj[this.assessmentType], this.pageTitleObj[this.assessmentType], this.routePathObj[this.assessmentType])
  },
  created() {
    if (this.pId) {
      this.form.id = this.pId
      this.getPiaAssessById()
    }
    this.getTemplateInfo()
    this.getUserSelect()
  },
  methods: {
    changeStart() {

    },
    changeEnd() {

    },
    // 获取模板信息
    getTemplateInfo() {
      GetTemplateInfo(this.assessCode, this.templateCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.opportunityList = resData.data.opportunityList ? resData.data.opportunityList : []
          this.conditionsList = resData.data.conditionsList ? resData.data.conditionsList : []
          this.assessTypeName = resData.data.questionnaireName
          this.form.assessTargetType = resData.data.assessTargetType
          this.form.questionnaireCode = resData.data.questionnaireCode
          this.targetFieldOperate = resData.data.targetFieldOperate
          this.isOutsidersWriter = resData.data.isOutsidersWriter
          this.isProcess = resData.data.isProcess
          // assessTarget去判断显示 0就是无 1组织这个就是选部门 2业务 3第三方 评估对象类型：1：组织，2：业务，3：第三方，4：IT资产
          if (this.form.assessTargetType === 1) {
            this.getThirdPartyOptions()
            this.listDept()
          }
          if (this.form.assessTargetType === 2) {
            this.getAllProduct()
          }
          if (this.form.assessTargetType === 3) {
            this.getThirdPartyOptions()
          }
        }
      })
    },
    listDept() {
      QueryDeptOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.deptOptions = resData.data
        }
      })
    },
    personSelectDialogShow() {
      this.$refs.personSelect.dialogVisible = true
    },
    personSelectDialogShow2() {
      this.$refs.personSelect2.dialogVisible = true
    },
    back() {
      this.$router.push({ path: this.routePathObj[this.assessmentType] })
    },
    getPiaAssessById() {
      GetAssessmentInfo(this.assessCode, this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          // 业务获取字段下拉
          if (this.form.assessTargetId) {
            this.getProductFieldList()
          }
        }
      })
    },
    getThirdPartyOptions() {
      GetThirdPartyOptionList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assessObjectOptions = resData.data
        }
      })
    },
    getAllProduct() {
      getAllProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assessObjectOptions = resData.data
        }
      })
    },
    getProductFieldList() {
      this.sourceFiledList = []
      GetProductFieldList({ id: this.form.assessTargetId }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.sourceFiledList = resData.data
        }
      })
    },
    getUserSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.users = res.data.data
        }
      })
    },
    formSubmitted() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          saveOrUpdatePiaAssess(this.assessCode, this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style>
.scance-form-item .el-checkbox__label {
  line-height: initial;
}
.scance-form-item .el-form-item__content,
.scance-form-item .el-form-item__content {
  line-height: initial;
}
.scance-form-item .el-form-item__error {
  padding-top: 0 !important;
}
.scance-form-item .el-radio-group {
  margin-top: 13px;
}
</style>
